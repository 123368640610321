RBEloginUx = {};

$(window).on("orientationchange", function(event) {
	RBEloginUx.setDeviceParametersInHTML();
});

$(window).on('resize', function(event) {
	RBEloginUx.setDeviceParametersInHTML();
});

RBEloginUx.setDeviceParametersInHTML = function() {
	"use strict";
	var dHeight = $(window).height(), dWidth = ($(window).width());
	$("#outerFrameDiv").css({
		width : dWidth + "px",
		height : dHeight + "px"
	}); // set outer frame
	// $("#loginTbl").css({
	// 	"padding-top" : (0.25 * dHeight) + "px"
	// });
};

RBEloginUx.copySVGs = function() {
	"use strict";
	var buttonSvgIDs = [ "loginfb", "loginGoogle", "loginAWS", "loginMyID", "loginOKTA"];
	RBEsvg.initializeSVG("login");
    RBEsvg.copySVGs(buttonSvgIDs);
};

RBEloginUx.initialzeEvents = function() {
	"use strict";
	// initialize all button click events
	$("Button").on("click", RBEloginUx.customClick);
};

RBEloginUx.rememberLoad = function () {
	if (localStorage.chkbxmorph && localStorage.chkbxmorph != '') {
		$('#remember_me').attr('checked', 'checked');
		$('#loginName').val(localStorage.usrnamemorph);
		$('#loginName').val(localStorage.usrnamemorph);
		$('#loginName').attr('value', localStorage.usrnamemorph);
	} else {
		$('#remember_me').removeAttr('checked');
		$('#loginName').val('');
		$('#loginName').attr('value', '');
	}

	$('#remember_me').click(function () {
		if ($('#remember_me').is(':checked')) {
			// save username and password
			localStorage.usrnamemorph = $('#loginName').val();
			localStorage.chkbxmorph = $('#remember_me').val();
		} else {
			localStorage.usrnamemorph = '';
			localStorage.chkbxmorph = '';
		}
	});
};

RBEloginUx.showHideLoginButtons = function() {
	let urlStr = window["location"]["href"];
	let isSeaHSUrl = (urlStr["indexOf"]("sea-hotstar.morphient.com") >= 0);
	if (!isSeaHSUrl) {
		$("#loginMyIDButton").addClass("hide");
	}
};

RBEloginUx.initializePage = function() {
	"use strict";
	sessionStorage.removeItem('response');
	RBEloginUx.setDeviceParametersInHTML();
	RBEloginUx.copySVGs();
	RBEloginUx.initialzeEvents();
	RBEloginUx.rememberLoad();
	$("#loginDiv").removeClass("hide");
	RBEloginUx.showHideLoginButtons();
	$("#loginName").focus();
	return;
};

RBEloginUx.customClick = function (event) {
	"use strict";
	try {
		if ($('#remember_me').is(':checked')) {
			// save username and password
			localStorage.usrnamemorph = $('#loginName').val();
			localStorage.chkbxmorph = $('#remember_me').val();
		} else {
			localStorage.usrnamemorph = '';
			localStorage.chkbxmorph = '';
		}
		var button = this, callBkFn = null, clkParm = {
			button: button,
			event: event
		};
		switch (button.id) {
			case ("loginfbButton"):
				RBEloginUx.autherizer = "fb";
				callBkFn = RBEloginUx["loginClick"];
				break;
			case ("loginGoogleButton"):
				RBEloginUx.autherizer = "google";
				callBkFn = RBEloginUx["loginClick"];
				break;
			case ("loginAWSButton"):
				RBEloginUx.autherizer = "amazon";
				callBkFn = RBEloginUx["loginClick"];
				break;
			case ("loginMyIDButton"):
				RBEloginUx.autherizer = "disney_myid";
				callBkFn = RBEloginUx["loginClick"];
				break;
			case ("loginOKTAButton"):
				RBEloginUx.autherizer = "okta";
				callBkFn = RBEloginUx["loginClick"];
				break;

			default:
				alert("RBEloginUx.customClick: unknown button, " + button.id + " clicked, ignored");
		}
		$(button).removeClass("off").addClass("on");
	} catch (e) {
		alert("Something went wrong while login, please contact your administrator...");
		console.log("Got error:", e);
	}

	setTimeout(function () {
		$(button).removeClass("on").addClass("off");
		callBkFn(clkParm);
	}, 200);
};

RBEloginUx.loginClick = function() {
	"use strict";
	try {
        RBEloginUx.abeLoginName = $('#loginName').val().toLowerCase();
        if (RBEloginUx.isValidLogin()) {
            switch (RBEloginUx.autherizer) {
				case "fb":
					RBEloginUx.windowTitle = "Facebook Login";
					break;
				case "amazon":
					RBEloginUx.windowTitle = "Amazon Login";
					break;
				case "google":
					RBEloginUx.windowTitle = "Google+ Login";
					break;
				case "disney_myid":
					RBEloginUx.windowTitle = "MyID Login";
					break;
				case "okta":
					RBEloginUx.windowTitle = "Okta Login";
					break;
            }
            RBEloginUx.login.doLogin(RBEloginUx.loginSuccess,
                    RBEloginUx.loginFailure);
        } else {
            alert("Please enter loginName to login...");
        }
	} catch(e) {
	    alert("Something went wrong while login, please contact your administrator...");
        console.log("Got error:", e);
	}
};

RBEloginUx.isValidLogin = function() {
	"use strict";
	if ($('#loginName').val() && $('#loginName').val() != "") {
		return true;
	}
	return false;
};

RBEloginUx.loginFailure = function(error) { // Error Callback for login: @param
	"use strict";
	// error
	error = (typeof error == 'object') ? JSON.stringify(error) : error;
	alert("Error occured during login : " + error);
};

RBEloginUx.loginSuccess = function(response) {
	"use strict";
	response.abeLoginName = RBEloginUx.abeLoginName;
	sessionStorage.setItem('response', JSON.stringify(response));
	window.open('dashboard.html', '_self', false);
};

window['RBEloginUx'] = RBEloginUx;
window['RBEloginUx']['initializePage'] = RBEloginUx.initializePage;
window['RBEloginUx']['loginClick'] = RBEloginUx.loginClick;